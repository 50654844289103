export const info = [
  // {
  //   jobid: "1",
  //   header: "Azure Security Expert",
  //   location: "Chennai, Tamil Nadu, India",
  //   workHome: "On-Site",
  //   initialDate: "2024-08-26T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager",

  //   requriedexp: "3+ Years ",
  //   exp: "3",
  //   linkId: "4011987477&f_C=102698672",
  //   content:
  //     "Charles Technologies is an innovative startup based in Chennai, dedicated to leveraging cutting-edge technologies to deliver exceptional solutions. We are seeking a skilled Azure Security Expert to join our dynamic team and ensure the security of our cloud infrastructure.",

  //   requriedexp: [
  //     "Minimum of 5 years of experience in cloud security, with a focus on Azure.",
  //   ],
  //   qualification: [
  //     "Bachelor of Engineering in Computer Science, Information Technology, or a related field.",
  //   ],

  //   responsePoints: [
  //     "Design and implement security solutions to protect Azure cloud infrastructure.",
  //     "Monitor and respond to security incidents and vulnerabilities.",
  //     "Conduct regular security assessments and audits to ensure compliance with industry standards.",
  //     "Collaborate with development and operations teams to integrate security into the DevOps process.",
  //     "Develop and maintain security policies, procedures, and documentation.",
  //     "Provide guidance and training to team members on security best practices.",
  //     "Stay updated with the latest security trends and threats to proactively address potential risks.",
  //   ],
  //   Qualification: [
  //     "Extensive experience in securing Azure cloud environments",
  //     "Strong knowledge of Azure security best practices and tools",
  //     "Proficiency in implementing and managing security policies, identity and access management, and threat protection.",
  //     "Familiarity with compliance standards and frameworks (e.g., DPDPA, PIPEDA, GDPR, CCPA), and their implications on data collection and storage.",
  //     "Excellent problem-solving and analytical skills.",
  //   ],
  //   Perks: [
  //     "Central Chennai Office: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },

  // {
  //   jobid: "2",
  //   header: "Mobile Application Developer",
  //   location: "Chennai",
  //   workHome: "On-Site",
  //   initialDate: "2024-09-21T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager",
  //   linkId: "mobile-app-developer-at-charles-technologies-4019941174",
  //   exp: "3 Years",
  //   qual:"Bachelor’s degree in Computer Science, Information Technology, or a related field.",
  //   skil:"React Native,React,Node.js ",
  //   content:
  //     "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented Mobile Application Developer to join our team and contribute to our exciting projects.",
  //   requriedexp: [
  //     "Minimum of 3 years of experience in mobile application development.",
  //   ],
  //   qualification: [
  //     "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
  //   ],
  //   foundationalKnowledge: [
  //     "Strong understanding of software engineering principles, product development, and mobile application development best practices.",
  //   ],
  //   skills: [
  //     "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features.",
  //     "React Native: Advanced expertise in React Native for developing cross-platform mobile applications, including experience with native modules and performance optimization.",
  //     "React: Proficient in React for front-end development, including hooks, context API, and state management libraries like Redux.",
  //     "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming.",
  //     "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying.",
  //     "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage.",
  //     "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows.",
  //     "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing.",
  //     "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms.",
  //     "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces.",
  //   ],

  //   responsePoints: [
  //     "Application Development: Develop and maintain high-quality mobile applications using React Native and React, ensuring code quality and performance.",
  //     "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security.",
  //     "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning.",
  //     "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
  //     "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring.",
  //     "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
  //     "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging.",
  //     "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
  //     "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
  //     "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
  //     "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
  //   ],

  //   Perks: [
  //     "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },

  {
    jobid: "2",
    header: "React Native Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-09-23T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    linkId: "react-native-developer-at-charles-technologies-4032017315",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field.",
    skil: "React Native,React,Node.js ",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced React Native Developer to join our team and contribute to the development of cutting-edge mobile applications.",
    requriedexp: [
      "Minimum of 2 years of experience in mobile application development using React Native.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and mobile application development best practices.",
    ],
    skills: [
      "React Native: Advanced expertise in React Native for developing cross-platform mobile applications, including experience with native modules and performance optimization.",
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features. ",
      "React: Proficient in React for front-end development, including hooks, context API, and state management libraries like Redux.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage.",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces.",
    ],

    responsePoints: [
      "Application Development: Develop and maintain high-quality mobile applications using React Native and React, ensuring code quality and performance.",
     "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security.",
     "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning.",
     "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
     "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring.",
     "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
     "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging.",
     "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
     "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
     "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
     "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  // {
  //   jobid: "3",
  //   header: " Software Engineer",
  //   location: "Chennai, Tamil Nadu, India",
  //   workHome: "On-Site",
  //   initialDate: "2024-09-04T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager ",
  //   qualification: "MCA/BE/BTech ",
  //   requriedexp: "3+ Years ",
  //   linkId: "4014527074_C=102698672",
  //   exp: "3",
  //   content:
  //     "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Software Engineer to join our team and contribute to the development of cutting-edge web and mobile applications.",
  //   requriedexp: [
  //     "Minimum of 3 years of experience in full stack application development.",
  //   ],
  //   qualification: [
  //     "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications are a plus.",
  //   ],

  //   responsePoints: [
  //     "Full Stack Development: Develop and maintain high-quality web and mobile applications using React Native, React, and Node.js, ensuring code quality and performance.",
  //     "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability and security.",
  //     "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, and performance tuning.",
  //     "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members. ",
  //     "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, and monitoring.",
  //     "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
  //     "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling and debugging.",
  //     "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
  //     "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing.",
  //     "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
  //     "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
  //   ],
  //   Qualification: [
  //     "Foundational Knowledge: Strong understanding of software engineering principles, product development, and web/mobile application development best practices.",
  //   ],
  //   skill: [
  //     "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features.",
  //     "React Native: Experience in developing cross-platform mobile applications using React Native.",
  //     "React: Advanced expertise in React for front-end development, including hooks, context API, and state management libraries like Redux.",
  //     "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js and asynchronous programming.",
  //     "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, and querying.",
  //     "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, and Azure Storage.",
  //     "Git: Proficient in version control systems like Git, including branching, merging strategies, and pull request workflows.",
  //     "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, and automated testing.",
  //     "API Integration: Experience in integrating RESTful APIs and third-party services, including authentication and authorization mechanisms.",
  //     "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive and user-friendly interfaces.",
  //   ],
  //   Perks: [
  //     "Central Chennai Office: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },

  {
    jobid: "4",
    header: "Product Owner",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-09-21T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field.",
    skil: "Roadmap Development,Backlog Prioritization",
    linkId: "product-owner-at-charles-technologies-4019940058",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a passionate and experienced Product Owner to join our team and drive the development of our cutting-edge web and mobile applications.",
    requriedexp: [
      " Minimum of 3 years of experience as a Product Owner or in a similar role in product management.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and agile methodologies.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, Business Administration, or a related field. Relevant certifications (e.g., CSPO, PSPO) are a plus.",
    ],
    skills: [
      "Roadmap Development: Ability to create and maintain a product roadmap that aligns with business goals and customer needs.",
      "Backlog Prioritization: Expertise in prioritizing the product backlog based on business value, customer feedback, and technical feasibility.",
      "User Story Creation: Proficiency in writing clear and concise user stories with well-defined acceptance criteria.",
      "Stakeholder Management: Strong skills in managing and communicating with stakeholders at all levels to gather requirements, provide updates, and align on priorities.",
      "Market Research: Experience in conducting market research and competitive analysis to inform product decisions and identify opportunities.",
      "Data-Driven Decision Making: Ability to use data and analytics to make informed product decisions and measure the success of product features.",
      "UI/UX Collaboration: Understanding of UI/UX design principles and ability to collaborate with designers to create user-friendly interfaces.",
      "Agile Methodologies: Extensive experience with agile methodologies, including Scrum and Kanban, and conducting agile ceremonies such as sprint reviews, retrospectives, and daily stand-ups.",
      "Technical Acumen: Basic understanding of web and mobile technologies, including JavaScript, React, Node.js, and cloud services like Azure.",
    ],

    responsePoints: [
      "Product Vision: Define and communicate the product vision and strategy to the development team and stakeholders.",
      "Backlog Management: Create, prioritize, and maintain the product backlog, ensuring alignment with business goals and customer needs.",
      "User Stories: Write clear and concise user stories with well-defined acceptance criteria.",
      "Sprint Planning: Participate in sprint planning meetings to ensure the development team understands the requirements and priorities.",
      "Daily Collaborative Development: Engage in daily stand-ups and collaborative development sessions to ensure alignment and progress.",
      "Agile Ceremonies: Conduct agile ceremonies such as sprint reviews, retrospectives, and daily stand-ups to ensure effective team collaboration and continuous improvement.",
      "Stakeholder Communication: Act as the primary point of contact for stakeholders, providing regular updates on product progress and gathering feedback.",
      "Market Research: Conduct market research and competitive analysis to identify opportunities and inform product decisions.",
      "UI/UX Collaboration: Work closely with designers to create intuitive and user-friendly interfaces.",
      "Quality Assurance: Collaborate with QA engineers to ensure the product meets quality standards and acceptance criteria.",
      "Performance Metrics: Define and track key performance metrics to measure the success of the product.",
      "Continuous Improvement: Continuously evaluate and improve product management processes and tools. Stay updated with the latest industry trends and best practices.",
    ],
    Qualification: [],
    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },

  // {
  //   jobid: "5",
  //   header: "Azure Solution Architect",
  //   location: "Chennai, Tamil Nadu, India",
  //   workHome: "On-Site",
  //   initialDate: "2024-08-31T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager",
  //   exp: "6",
  //   linkId: "4011973711&f_C=102698672",
  //   content:
  //     "Charles Technologies is an innovative startup based in Chennai, focused on leveraging cutting-edge technologies to deliver exceptional solutions. We are seeking a skilled Azure Solution Architect to join our dynamic team and drive our projects to new heights.",
  //   requriedexp: [
  //     "Minimum of 5 years of experience in solution architecture and cloud services.",
  //   ],
  //   qualification: [
  //     "Bachelor of Engineering in Computer Science, Information Technology, or a related field.",
  //   ],
  //   skills: ["MCA/BE/BTech "],
  //   responsePoints: [
  //     "Design and implement scalable, secure, and high-performance solutions using Azure Cloud Services. ",
  //     "Lead the architecture and development of mobile applications using React Native.",
  //     "Collaborate with cross-functional teams to define technical requirements and deliver solutions.",
  //     "Ensure the integration of various systems and applications within the Azure ecosystem.",
  //     "Optimize cloud infrastructure for performance, cost, and security.",
  //     "Participate in Agile development processes, including sprint planning, daily stand-ups, and retrospectives.",
  //     "Mentor and guide junior developers and team members.",
  //   ],
  //   Qualification: [
  //     "Proficiency in React Native for mobile application development.",
  //     "Strong expertise in Node.js for backend development.",
  //     "Experience with Azure Cosmos DB for database management.",
  //     "In-depth knowledge of Agile development methodologies using Azure DevOps.",
  //     "Expertise in CI/CD Pipelines and Azure Cloud Services for deployment and hosting.",
  //   ],
  //   Perks: [
  //     "Central Chennai Office: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },

  {
    jobid: "6",
    header: "Quality Assurance Test Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-10-03T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., ISTQB, CSTE) are a plus.",
    skil: "Scripting Languages,Automation Testing",
    linkId: "quality-assurance-test-engineer-at-charles-technologies-4038826414",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a meticulous and experienced Quality Assurance Test Engineer to join our team and ensure the highest quality standards for our web and mobile applications.",
    requriedexp: [
      "Minimum of 3 years of experience in quality assurance and testing.",
    ],
    qualification: [
      "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., ISTQB, CSTE) are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and quality assurance best practices.",
    ],
    skills: [
      "Scripting Languages: Proficiency in Java for test automation.",
      "Testing Frameworks: Proficiency in using testing frameworks and tools such as Selenium, Appium, JUnit, TestNG, and Postman.",
      "Automation Testing: Experience in developing and maintaining automated test scripts for web and mobile applications.",
      "Manual Testing: Strong skills in manual testing, including functional, regression, integration, and user acceptance testing.",
      "CI/CD Integration: Experience with integrating automated tests into CI/CD pipelines using Azure DevOps.",
      "Bug Tracking and Task Management: Proficiency in using Azure DevOps Boards for bug tracking, test planning, and task management.",
      "API Testing: Experience in testing RESTful APIs using tools like Postman or SoapUI.",
      "Performance Testing: Knowledge of performance testing tools such as JMeter or LoadRunner.",
    ],
    responsePoints: [
      "Test Planning: Develop comprehensive test plans and test cases based on project requirements and specifications using Azure DevOps Boards.",
      "Automation Testing: Design, develop, and maintain automated test scripts for web and mobile applications using tools like Selenium and Appium.",
      "Manual Testing: Conduct thorough manual testing to identify defects and ensure the functionality, usability, and performance of applications.",
      "CI/CD Integration: Integrate automated tests into CI/CD pipelines using Azure DevOps to ensure continuous testing and delivery.",
      "Bug Tracking: Log, track, and manage defects using Azure DevOps Boards, and work closely with developers to resolve issues.",
      "API Testing: Perform API testing to validate the functionality, reliability, and performance of backend services.",
      "Performance Testing: Conduct performance testing to identify bottlenecks and ensure applications meet performance requirements.",
      "Collaboration: Work closely with developers, product owners, and other stakeholders to understand requirements and ensure quality throughout the development lifecycle.",
      "Peer Code Reviews: Participate in peer code reviews to ensure test scripts adhere to coding standards and best practices.",
      "Documentation: Create and maintain comprehensive documentation for test plans, test cases, and test results.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve testing practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
  {
    jobid: "7",
    header: "Manager - Finance & Accounts",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-09-21T00:00:00",
    Discipline: "Technology",
    reporting: "DGM-HR",
    exp: "8 Years",
    qual: "MBA in Finance or Accounting from a reputed university or college, and a Bachelor’s degree in Finance, Accounting, or a related field.",
    skil: "Leadership,Team Management",
    linkId: "manager-finance-accounts-at-charles-technologies-4019940697",
    content:
      "Charles Technologies is an innovative startup based in Chennai, dedicated to leveraging cutting-edge technologies to deliver exceptional solutions. We are seeking a seasoned Manager - Finance and Accounting to join our dynamic team and oversee our financial operations. ",
    requriedexp: [
      "Minimum of 8 years of experience in financial management, with at least 3 years in a leadership role. Significant experience working with startups, including financial planning, budgeting, and analysis.",
    ],
    qualification: [
      "MBA in Finance or Accounting from a reputed university or college, and a Bachelor’s degree in Finance, Accounting, or a related field. CPA, CMA, or Chartered Accountant (CA) certification is highly desirable.",
    ],
    skills: [
      "Leadership: Excellent leadership and project management skills.",
      "Communication: Strong communication and interpersonal skills.",
      "Team Management: Ability to lead and mentor a diverse team of finance professionals.",
      "Technical Knowledge: Strong knowledge of financial software and systems.",
      "Regulatory Compliance: In-depth understanding of financial regulations and compliance requirements.",
      "Analytical Skills: Proficiency in financial analysis and forecasting.",
      "Strategic Planning: Ability to develop and implement financial strategies that align with business goals.",
    ],
    responsePoints: [
      "Financial Management: Oversee all financial operations, including accounting, budgeting, and financial reporting.",
      "Strategy Development: Develop and implement financial strategies to support the company’s growth and profitability.",
      "Budget Management: Manage budgets for various departments and ensure cost-effectiveness.",
      "Compliance: Ensure compliance with all financial regulations and standards.",
      "Financial Reporting: Prepare and present financial reports to the executive team and stakeholders.",
      "Risk Management: Identify and mitigate financial risks.",
      "Team Leadership: Lead and mentor a team of finance professionals, fostering a culture of excellence and continuous improvement.",
      "Performance Reviews: Conduct regular performance reviews and provide guidance and training to team members.",
      "Technology Integration: Oversee the integration of financial systems with other business systems to ensure seamless operations.",
      "Trend Analysis: Stay updated with the latest financial trends and best practices to drive the company’s financial performance.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
  {
    jobid: "8",
    header: "Full Stack Developer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-09-24T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor's degree in Computer Science, Information Technology, or a related field. A Master’s degree is a plus. Relevant certifications are also a plus.",
    skil: "React Native,React,Node.js",
    linkId: "senior-full-stack-developer-at-charles-technologies-4032909169",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced MERN Stack Developer to join our team and lead the development of innovative web and mobile applications.",
    requriedexp: [
      "Minimum of 3 years of total experience in full stack application development. Extensive experience working with startups, small teams, and in fast-paced environments is highly desirable.",
    ],
    qualification: [
      "Bachelor's degree in Computer Science, Information Technology, or a related field. A Master’s degree is a plus. Relevant certifications are also a plus. ",
    ],
    foundationalKnowledge: [
      "Strong understanding of software engineering principles, product development, and web/mobile application development best practices. ",
    ],
    skills: [
      "JavaScript: Expert-level proficiency in JavaScript, including ES6+ features, asynchronous programming, and modern frameworks.",
      "React Native: Extensive experience in developing cross-platform mobile applications using React Native, including performance optimization and native module integration. ",
      "React: Advanced expertise in React for front-end development, including hooks, context API, state management libraries like Redux, and component lifecycle management.",
      "Node.js: Solid knowledge of Node.js for backend development, including experience with Express.js, RESTful API design, and asynchronous programming patterns.",
      "Azure Cosmos DB: Extensive experience with Azure Cosmos DB for scalable and efficient data management, including partitioning, indexing, querying, and performance tuning.",
      "Azure Cloud Services: Proficiency in deploying and managing applications on Azure Cloud Services, including Azure App Services, Azure Functions, Azure Storage, and monitoring tools. ",
      "Git: Proficient in version control systems like Git, including branching, merging strategies, pull request workflows, and conflict resolution.",
      "Azure DevOps: Experience with Azure DevOps for CI/CD pipelines, project management, automated testing, and release management.",
      "API Integration: Experience in integrating RESTful APIs and third-party services, including OAuth, JWT, and other authentication and authorization mechanisms.",
      "UI/UX Design: Understanding of UI/UX design principles and ability to collaborate with designers to implement responsive, accessible, and user-friendly interfaces.",
    ],
    responsePoints: [
      "Full Stack Development: Develop and maintain high-quality web and mobile applications using React Native, React, and Node.js, ensuring code quality, performance, and scalability.",
      "Backend Development: Implement backend services and APIs using Node.js, ensuring scalability, security, and maintainability.",
      "Database Management: Manage and optimize databases using Azure Cosmos DB, including data modelling, indexing, partitioning, and performance tuning.",
      "Version Control: Use Git for version control, including branching, merging, and pull request workflows. Conduct peer code reviews to ensure code quality and share knowledge with team members.",
      "CI/CD Pipelines: Set up and maintain CI/CD pipelines using Azure DevOps, including automated testing, deployment, monitoring, and rollback strategies.",
      "Peer Code Reviews: Participate in peer code reviews to ensure adherence to coding standards, identify potential issues, and share best practices.",
      "Performance Optimization: Optimize application performance and ensure responsiveness across different devices and platforms, including profiling, debugging, and performance tuning.",
      "Collaboration: Work closely with designers, product owners, and other developers to deliver high-quality applications. Participate in agile development processes, including sprint planning, stand-ups, and retrospectives.",
      "Testing and Debugging: Conduct thorough testing and debugging to ensure the reliability and stability of applications, including unit testing, integration testing, and end-to-end testing. ",
      "Documentation: Create and maintain comprehensive documentation for code, APIs, and development processes, including technical specifications and user guides.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies, and continuously improve development practices. Participate in knowledge-sharing sessions and contribute to the growth of the team.",
    ],

    Perks: [
      "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
  {
    jobid: "9",
    header: "Machine Learning Engineer",
    location: "Chennai",
    workHome: "On-Site",
    initialDate: "2024-09-21T00:00:00",
    Discipline: "Technology",
    reporting: "Product Manager",
    exp: "3 Years",
    qual: "Bachelor of Engineering in Computer Science, Data Science, AI or Machine Learning. A Master’s degree or relevant certifications are a plus.",
    skil: "Programming Languages,Cloud Services",
    linkId: "machine-learning-engineer-at-charles-technologies-4020615020",
    content:
      "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a talented and experienced Machine Learning Engineer to join our team and contribute to the development of advanced machine learning solutions that enhance our mobile applications.",
    requriedexp: [
      "Minimum of 3 years of experience in machine learning engineering or a related field. Experience working in an Agile Product Development",
    ],
    qualification: [
      "Bachelor of Engineering in Computer Science, Data Science, AI or Machine Learning. A Master’s degree or relevant certifications are a plus.",
    ],
    foundationalKnowledge: [
      "Strong understanding of machine learning algorithms, software engineering, statistical analysis, and data mining techniques. Knowledge of product development and mobile application development is a plus.",
    ],
    skills: [
      "Programming Languages: Proficiency in Python and JavaScript for developing machine learning models and integrating them with applications.",
      "Machine Learning Frameworks: Experience with libraries and frameworks such as Pandas, Scikit-learn and XGBoost. Experience with Tensorflow.js is a plus. Proficiency in Azure Machine Learning Services.",
      "Data Visualization: Proficiency in JavaScript libraries like Plotly.js and D3.js for visualizing model outputs and data insights.  ",
      "SQL and Azure Cosmos DB: Strong knowledge of SQL for data querying and manipulation. Proficiency in Azure Cosmos DB for scalable and efficient data management.",
      "Cloud Services: Experience with Azure for deploying machine learning models and solutions.",
      "Azure DevOps: Proficiency in Azure DevOps, including Repos, Pipelines, and Kanban boards.",
      "Version Control: Proficient in version control systems like Git.",
      "API Integration: Experience in integrating machine learning models with RESTful APIs and third-party services.",
    ],
    responsePoints: [
      "Model Development: Design, develop, and implement machine learning models to solve business problems and enhance mobile applications.",
      "Data Management: Manage and optimize data storage and retrieval processes, ensuring data quality and integrity.",
      "Collaboration: Work closely with data scientists, mobile application developers, product owners, and other stakeholders to understand requirements and deliver machine learning solutions.",
      "Performance Optimization: Optimize machine learning models for performance and scalability, including profiling and debugging.",
      "Visualization: Create and maintain dashboards and reports to visualize model outputs and track key performance indicators.",
      "Documentation: Document machine learning processes, models, and analysis results, ensuring transparency and reproducibility.",
      "Continuous Improvement: Stay updated with the latest industry trends and technologies in machine learning and continuously improve development practices.",
      "Testing and Validation: Conduct thorough testing and validation of machine learning models to ensure accuracy and reliability.",
      "Peer Reviews: Participate in peer reviews of machine learning models and code to ensure quality and share best practices.",
    ],

    Perks: [
      "Central Location: Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
      "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
      "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
      "Professional Development: Opportunities for continuous learning and growth.",
      "Team Outings and Events: Regular team-building activities and events.",
      "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
    ],
  },
  // {
  //   jobid: "10",
  //   header: "Presales Engineer",
  //   location: "Chennai",
  //   workHome: "On-Site",
  //   initialDate: "2024-09-21T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager",
  //   exp: "3 Years",
  //   qual: "Bachelor of Engineering or Technology in Computer Science, Information Technology, or a related field.",
  //   skil: "Technical Demonstrations,Customer Analysis,",
  //   linkId: "presales-engineer-at-charles-technologies-4020611825",
  //   content:
  //     "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a Presales Engineer who will play a pivotal role in driving the success of our sales team by providing technical expertise and support throughout the sales cycle. You will work closely with the Business Leadership team, prospective customers, and end users to understand their business needs and challenges, and then demonstrate how our solutions can address them.",
  //   requriedexp: [
  //     "Minimum of 3 years of experience in a presales or technical sales role, with a strong track record of supporting sales teams in a technical capacity. Experience in the mobile application development industry is highly desirable. Startup experience is a plus.",
  //   ],
  //   qualification: [
  //     "Bachelor of Engineering or Technology in Computer Science, Information Technology, or a related field. Advanced certifications in mobile application development are a plus. An MBA is highly desirable.",
  //   ],
  //   foundationalKnowledge: [
  //     "Excellent communication and interpersonal skills, with the ability to convey complex technical concepts to diverse audiences. Ability to work independently and collaboratively within a team environment.",
  //   ],
  //   skills: [
  //     "Technical Demonstrations: Expertise in conducting technical demos and presentations, and designing proof-of-concept (PoC) projects.",
  //     "Customer Analysis: Strong skills in understanding and analyzing customer requirements, and creating technical documentation.",
  //     "Sales Collaboration: Experience in collaborating with sales teams and supporting the sales process. ",
  //     "Market Insight: Ability to stay updated with industry trends, competitor activities, and provide market insights.",
  //     "Communication & Problem-Solving: Excellent verbal and written communication skills, and strong analytical and problem-solving abilities.",
  //     "Presentation Skills: Proficiency in building compelling PowerPoint decks, incorporating technical details, and conducting engaging, technically sound demonstrations.",
  //     "Rapid Execution: Ability to quickly collect high-level technical requirements, develop rapid prototypes, and deliver effective solutions within tight timelines.",
  //   ],
  //   responsePoints: [
  //     "Technical Expertise: Master our product offerings, features, and benefits, conduct technical demos and presentations, and design and implement proof-of-concept (PoC) projects.",
  //     "Customer Engagement: Build strong relationships with prospective customers, identify and qualify potential sales opportunities, and address customer inquiries while gathering requirements.",
  //     "Sales Support: Collaborate with sales teams to develop strategies, provide technical support during the sales process, and assist in creating sales proposals and presentations.",
  //     "Market Analysis: Stay updated with industry trends and competitor activities, provide insights into market opportunities and challenges, and analyze customer feedback to refine product offerings.",
  //   ],

  //   Perks: [
  //     "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },
  // {
  //   jobid: "11",
  //   header: "Scrum Master",
  //   location: "Chennai",
  //   workHome: "On-Site",
  //   initialDate: "2024-09-21T00:00:00",
  //   Discipline: "Technology",
  //   reporting: "Product Manager",
  //   exp: "2 Years",
  //   qual: "Bachelor’s degree in Computer Science, Information Technology, or a related field",
  //   skil: "Agile Methodologies,Facilitation",
  //   linkId: "scrum-master-at-charles-technologies-4030650788",
  //   content:
  //     "Charles Technologies is a dynamic startup based in Chennai, dedicated to creating innovative mobile applications that transform user experiences. We are looking for a passionate and experienced Scrum Master to join our team and drive the development of our cutting-edge web and mobile applications.",
  //   requriedexp: [
  //     "Minimum of 2 years of experience as a Scrum Master or in a similar role in agile project management.",
  //   ],
  //   qualification: [
  //     "Bachelor’s degree in Computer Science, Information Technology, or a related field. Relevant certifications (e.g., CSM, PSM, CSPO) are a plus.",
  //   ],
  //   foundationalKnowledge: [
  //     "Strong understanding of software engineering principles, agile methodologies, and project management.",
  //   ],
  //   skills: [
  //     "Agile Methodologies: Extensive experience with agile methodologies, including Scrum and Kanban, and conducting agile ceremonies such as sprint reviews, retrospectives, and daily stand-ups.",
  //     "Facilitation: Expertise in facilitating agile ceremonies and ensuring effective team collaboration and communication.",
  //     "Conflict Resolution: Strong skills in conflict resolution and problem-solving to address team challenges and impediments.",
  //     "Coaching: Ability to coach and mentor team members on agile practices and principles.",
  //     "Stakeholder Management: Strong skills in managing and communicating with stakeholders at all levels to gather requirements, provide updates, and align on priorities.",
  //     "Continuous Improvement: Experience in driving continuous improvement initiatives and fostering a culture of learning and growth within the team.",
  //     "Technical Acumen: Basic understanding of web and mobile technologies, including JavaScript, React, Node.js, and cloud services like Azure.",
  //   ],
  //   responsePoints: [
  //     "Agile Facilitation: Facilitate agile ceremonies such as sprint planning, daily stand-ups, sprint reviews, and retrospectives to ensure effective team collaboration and progress.",
  //     "Impediment Removal: Identify and remove impediments that hinder the team’s progress and ensure a smooth workflow.",
  //     "Team Coaching: Coach and mentor team members on agile practices and principles to enhance their performance and productivity.",
  //     "Stakeholder Communication: Act as the primary point of contact for stakeholders, providing regular updates on project progress and gathering feedback.",
  //     "Sprint Planning: Collaborate with the Product Owner and development team to ensure clear understanding of requirements and priorities during sprint planning.",
  //     "Performance Metrics: Define and track key performance metrics to measure the success of the team and identify areas for improvement.",
  //     "Continuous Improvement: Drive continuous improvement initiatives and foster a culture of learning and growth within the team.",
  //     "Quality Assurance: Collaborate with QA engineers to ensure the product meets quality standards and acceptance criteria.",
  //     "Market Research: Conduct market research and competitive analysis to inform project decisions and identify opportunities.",
  //     "UI/UX Collaboration: Work closely with designers to create intuitive and user-friendly interfaces.",
  //     "Agile Best Practices: Stay updated with the latest industry trends and best practices in agile methodologies and project management.",
  //   ],

  //   Perks: [
  //     "Central Location:  Conveniently located in the heart of the city, with parking facilities, and well-served by public transport including buses and Chennai Metro.",
  //     "Meals and Refreshments: Lunch, tea/coffee, snacks, and refreshments provided throughout the day.",
  //     "Insurance: ICICI Lombard Family Group Insurance for INR 5.0 Lakhs (Coverage: Self + Spouse + Up to 2 Children).",
  //     "Professional Development: Opportunities for continuous learning and growth.",
  //     "Team Outings and Events: Regular team-building activities and events.",
  //     "Employee Recognition: Programs to acknowledge and reward outstanding performance.",
  //   ],
  // },
];
